<template>
  <section class="h-96 flex flex-col items-center justify-center">
    <div class="flex items-center">
      <h1 class="text-warn-light text-4xl pb-2 pr-6 mr-6 border-r-2">
        {{ $route.params.status || '404' }}
      </h1>
      <p class="text-lg">{{ $route.params.message || 'Page not found' }}</p>
    </div>
  </section>
</template>
